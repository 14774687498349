<template>
	<el-scrollbar class="uu-box">
		<div class="uu-rank">
			<div class="uu-screen">
				<el-form v-model="screenForm" size="small" :inline="true">
					<el-form-item :label="$t('seller.screen.classBelong')" v-if="userInfo.identity>1">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.shopName')">
						<el-input v-model="screenForm.store_name" :placeholder="$t('seller.placeholder.shopName1')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="handleSearchList" round>{{$t('seller.actions.search')}}</el-button>
						<span class="clear-btn" @click="handleSearchClear"><i class="el-icon-brush"></i>{{$t('seller.actions.reset')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="uu-rank-head">
				<div class="message-notice">
					<i class="el-icon-warning"></i>
					<div class="message-notice-main">
						<p>{{$t('seller.ranking.tipsTitle')}}</p>
						<p>{{$t('seller.ranking.tipsText1')}}</p>
						<p>{{$t('seller.ranking.tipsText2')}}</p>
					</div>
				</div>
			</div>
			<div class="uu-rank-main">
				<el-table :data="rankList" size="small" @sort-change="handleSortPageChange">
					<el-table-column :label="$t('seller.tableHead.ranking')" prop="rank" width="100px" ></el-table-column>
					<el-table-column :label="$t('seller.tableHead.userName')" prop="user_name" align="center"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.studentName')" prop="real_name" align="center"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.shopName')" prop="store_name" align="center"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.class')" prop="class_name" align="center"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.scoreSystem')" prop="system_score" align="center" sortable="custom"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.scoreTeacher')" prop="teacher_rating" align="center" sortable="custom"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.totalGrade')" prop="total_score" align="center" sortable="custom"></el-table-column>
				</el-table>
			</div>
			<div class="uu-rank-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { scoreRanking } from '@/api/seller/assess'
	import { fetchClass } from '@/api/teacher'
	
	const defaultForm = {
		store_name: '',
		class_id: ''
	}
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				screenForm: Object.assign({},defaultForm),
				classOption: [],
				rankList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				sortSign: '',
				sortType: '',
			}
		},
		mounted() {
			this.getRankList();
			if(this.userInfo.identity>1){
				this.getClassOption();
			}
		},
		methods: {
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getRankList() {
				let params = {
					...this.screenForm,
					sort_sign: this.sortSign,
					sort_type: this.sortType,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize,
				}
				scoreRanking(params).then(response => {
					this.rankList = response.data.list
					this.pageTotal = parseInt(response.data.count)
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getRankList();
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getRankList();
			},
			handleSearchList() {
				this.pageCurr = 1;
				this.getRankList();
			},
			handleSearchClear() {
				this.screenForm = Object.assign({},defaultForm)
				this.pageCurr = 1;
				this.getRankList();
			},
			handleSortPageChange(column) {
				switch (column.prop) {
					case 'system_score':
						this.sortSign = 1;
						break;
					case 'teacher_rating':
						this.sortSign = 2;
						break;
					case 'total_score':
						this.sortSign = 3;
						break;
				}
				if (column.order == 'ascending') {
					this.sortType = 'asc'
				} else {
					this.sortType = 'desc'
				}
				this.pageCurr = 1;
				this.getRankList()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.uu-rank {
		padding: 20px;
		box-sizing: border-box;

		.message-notice {
			padding: 12px;
			border-radius: 9px;
			line-height: 18px;
			background-color: $--seller-notice-background;
			margin-bottom: 15px;

			.el-icon-warning {
				color: $--seller-primary;
				float: left;
				font-size: 18px;
			}

			.message-notice-main {
				width: 100%;
				color: #666666;
				font-size: 14px;
				line-height: 22px;
				padding-left: 24px;
				padding-right: 15px;
				box-sizing: border-box;

				a {
					color: $--seller-primary;
				}
			}
		}

		.uu-screen {
			border-bottom: 1px solid #e6e8ed;
			margin-bottom: 16px;

			.clear-btn {
				cursor: pointer;
				color: #666666;
				margin-left: 12px;
		
				i {
					margin-right: 4px;
				}
			}
		
			.clear-btn:hover {
				color: $--seller-primary;
			}
		}

		.uu-rank-main {
			::v-deep .el-table {
				th {
					background-color: $--seller-thead-background-1;
				}
			}
		}

		.uu-rank-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
